import React from 'react';
import ReactDOM from 'react-dom';

import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

import App from './App';

const firebaseAccountJson = process.env.REACT_APP_FIREBASE_ACCOUNT;
const firebaseConfig = JSON.parse(firebaseAccountJson);
const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);

ReactDOM.render(<App />, document.getElementById('root'));