import { React, useState, useEffect } from 'react';
import { Avatar, useChatContext } from 'stream-chat-react';
import deleteIcon from '../assets/delete-icon.png';


const TeamChannelPreview = ({ setActiveChannel, setIsCreating, setIsEditing, setToggleContainer, channel, type }) => {
    const { channel: activeChannel, client } = useChatContext();
    const [unreadCount, setUnreadCount] = useState(0);
    const members = Object.values(channel.state.members).filter(({ user }) => user.id !== client.userID);
        const lastMessage = channel.state.messages[channel.state.messages.length - 1];
        const isLastMessageSentByMe = lastMessage?.user?.id === client.userID;
        const channelWrapperClass = isLastMessageSentByMe ? 'channel-preview__wrapper sent-by-me' : 'channel-preview__wrapper sent-by-other';

    useEffect(() => {
        if (channel) {
            const fetchUnreadCount = async () => {
                const count = await channel.countUnread();
                setUnreadCount(count);
            };
            fetchUnreadCount();
        }
    }, [channel]);

    const ChannelPreview = () => (
        <p className="channel-preview__item">
            # {channel?.data?.name || channel?.data?.id}
        </p>
    );


    const DirectPreview = () => {
        
        //console.log(members[0]);

        const getTimeAgo = (timestamp) => {
            const now = new Date();
            const messageDate = new Date(timestamp);

            // Check if the message date is from yesterday
            if (
                now.getDate() - messageDate.getDate() === 1 &&
                now.getMonth() === messageDate.getMonth() &&
                now.getFullYear() === messageDate.getFullYear()
            ) {
                return "Yesterday";
            } else {
                const diff = now - messageDate;
                const seconds = Math.floor(diff / 1000);
                const minutes = Math.floor(seconds / 60);
                const hours = Math.floor(minutes / 60);
                const days = Math.floor(hours / 24);

                if (days > 7) {
                    const messageDate = new Date(timestamp);
                    const month = messageDate.getMonth() + 1;
                    const day = messageDate.getDate();
                    const year = messageDate.getFullYear().toString().slice(-2);
                    return `${month}/${day}/${year}`;
                } else if (days > 1) {
                    const messageDate = new Date(timestamp);
                    const dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][messageDate.getDay()];
                    return dayOfWeek;
                } else if (hours > 0) {
                    return `${hours} h`;
                } else if (minutes > 0) {
                    return `${minutes} min`;
                } else {
                    return "Now";
                }
            }
        };

        const handleDeleteChannel = async () => {
            try {
                await channel.delete();
                // Optionally, you can perform additional actions after deletion, such as navigating to a different page.
                console.log("Button clicked");
            } catch (error) {
                console.error('Error deleting channel:', error);
            }
        };

        return (
            <div className="channel-preview__item single">
                <div className="avatar-wrapper">
                    {/*<Avatar
                        image={members[0]?.user?.image}
                        name={members[0]?.user?.fullName || members[0]?.user?.id}
                        size={24}
                    />
                    */}
                </div>
                <div className="message-info">
                    <div className="name-time">
                        <p className="name">
                            {unreadCount > 0 && <span className="unread-count">&nbsp;&nbsp;</span>} {/*alternatively, display the unread count with {unreadCount}*/}
                            {members[0]?.user?.fullName || members[0]?.user?.id}
                        </p>
                        <p className={`time-ago ${isLastMessageSentByMe ? '' : 'time-ago-other'}`}>
            {lastMessage ? getTimeAgo(lastMessage.created_at) : ""}
          </p>
                    </div>
                    <div className="preview-delete">

                        <p className="last-message">
                            {/*<span className={isLastMessageSentByMe ? "sent-by-me" : "sent-by-other"}>
                            {isLastMessageSentByMe ? "You" : members[0]?.user?.fullName || members[0]?.user?.id}:
                </span>*/}{" "}
                            {lastMessage?.text || "No messages yet"}
                        </p>
                        {channel.data.category === 'sending_10am' && (
                            <div className="delete-background" onClick={handleDeleteChannel}>
                                <img
                                    src={deleteIcon}
                                    alt="Delete Channel"
                                    className="delete-icon"
                                />
                            </div>
                        )}
                    </div>

                </div>
            </div>
        );
    };





    return (
        <div //className={`${
           // channel?.id === activeChannel?.id ? 'channel-preview__wrapper__selected' : ''
          //} ${channelWrapperClass}`}
          className={
            channel?.id === activeChannel?.id
                ? 'channel-preview__wrapper__selected'
                : 'channel-preview__wrapper'
        }
          
            onClick={() => {
                setIsCreating(false);
                setIsEditing(false);
                setActiveChannel(channel);
                if (setToggleContainer) {
                    setToggleContainer((prevState) => !prevState)
                }
            }}
        >
            {type === 'messaging' && <DirectPreview />}

            {/*type === 'team' ? <ChannelPreview /> : <DirectPreview />*/}
        </div>
    );
}

export default TeamChannelPreview
